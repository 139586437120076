import Vue from 'vue';
import { mapGetters } from 'vuex';

function loadWithRetry(importFn, retries = 3) {
  return new Promise((resolve, reject) => {
    const attemptLoad = (retriesLeft) => {
      importFn()
        .then(resolve)
        .catch((error) => {
          if (retriesLeft <= 0) {
            reject(error);
          } else {
            setTimeout(() => attemptLoad(retriesLeft - 1), 1000);
          }
        });
    };
    attemptLoad(retries);
  });
}

const recaptchaImportMixin = {
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
  },
  methods: {
    async loadRecaptcha() {
      if (!window.recaptchaLoaded) {
        window.recaptchaLoaded = true;

        try {
          const { VueReCaptcha } = await loadWithRetry(() => import('vue-recaptcha-v3'), 3);
          Vue.use(VueReCaptcha, {
            siteKey: this.storeInformation.reCaptcha?.siteKey,
          });
          await this.$recaptchaLoaded();
        } catch (error) {
          console.error('Failed to load recaptcha:', error);
        }
      }
    },
    async getReCaptchaToken() {
      const isDev = process.env.IS_REMOTE || ['local', 'development'].includes(process.env.NODE_ENV);
      if (isDev) {
        return '';
      } else {
        await this.loadRecaptcha();
        return await this.$recaptcha('login');
      }
    },
  },
};

export default recaptchaImportMixin;

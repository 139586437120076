
  import { mapGetters } from 'vuex';
  export default {
    name: 'InventoryStatus',
    props: {
      status: String,
      inventoryQuantity: Number,
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
    },
  };


export default {
  name: 'b-dropdown-hover',
  data() {
    return {
      visible: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => [],
      required: true,
    },
    menuClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    onOver() {
      setTimeout(() => {
        if (!this.visible) {
          this.visible = true;
          this.$refs.dropdown.showMenu();
          this.$refs.dropdown.$refs.menu.style.display = 'block';
        }
      });
    },
    onLeave() {
      this.visible = false;
      this.$refs.dropdown.$refs.menu.style.display = 'none';
    },
  },
};

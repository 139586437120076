import Vue from 'vue';

const validateInputImportMixin = {
  data() {
    return {
      scriptValidatedInputLoaded: false,
    };
  },
  async created() {
    if (process.client) {
      const ValidatedInput = await import('@mtntop/validated-input');
      Vue.component('validated-input', ValidatedInput.ValidatedInput);
      setTimeout(() => (this.scriptValidatedInputLoaded = true));
      //
      /* const script = document.createElement('script');
      script.onload = async function() {
        const dict = {
          custom: {
            Quantity: {
              min_value: (field, val) => `Please enter a quantity of ${val} or greater`,
            },
          },
        };
        VeeValidate.Validator.localize('en', dict);
        VeeValidate.Validator.extend('partSizes', {
          getMessage(field, params, data) {
            return `Error`;
          },
          validate: async (value, [testProp]) => {
            return testProp === 1;
          },
        });
        VeeValidate.Validator.extend('increment', {
          getMessage(field, params, data) {
            return `Must be ordered in increments of ${params}`;
          },
          validate: (value, [testProp]) => {
            return parseInt(value) % parseFloat(testProp) === 0;
          },
        });

        VeeValidate.Validator.extend(
          'enum',
          {
            validate: (value, params) => {
              return params.values.includes(value);
            },
            getMessage: (field, params) => {
              return `can be only: ${params[0].join(', ')}`;
            },
          },
          {
            paramNames: ['values'],
          }
        );
        VeeValidate.Validator.extend('maxVal', {
          //standart max work only with digits
          getMessage(field, params, data) {
            return `Field should be less than ${params}`;
          },
          validate: (value, [testProp]) => {
            return parseFloat(value) <= parseFloat(testProp);
          },
        });
        VeeValidate.Validator.extend('minVal', {
          getMessage(field, params, data) {
            return `Field should be more than ${params}`;
          },
          validate: (value, [testProp]) => {
            return parseFloat(value) >= parseFloat(testProp);
          },
        });
        VeeValidate.Validator.extend('increment', {
          getMessage(field, params, data) {
            return `Field should be divisible by ${params}`;
          },
          validate: (value, [testProp]) => {
            return parseInt(value) % parseFloat(testProp) === 0;
          },
        });

        Vue.use(VeeValidate, {
          inject: false,
          fieldsBagName: 'veeFields',
        });

        const ValidatedInput = await import('@mtntop/validated-input');
        Vue.component('validated-input', ValidatedInput.ValidatedInput);

        this.scriptValidatedInputLoaded = true;
      };
      script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/vee-validate@%3C3.0.0/dist/vee-validate.js');
      document.body.appendChild(script);*/
    }
  },
};

const autocompleteImportMixin = {
  data() {
    return {
      scriptAutoCompleteInputLoaded: false,
    };
  },
  async created() {
    if (process.client) {
      const { AutoCompleteInput, Multiselect } = await import('@mtntop/validated-input');
      Vue.component('multiselect', Multiselect);
      Vue.component('autocomplete-input', AutoCompleteInput);
      this.scriptAutoCompleteInputLoaded = true;
      setTimeout(() => (this.scriptAutoCompleteInputLoaded = true));
    }
  },
};
const multiselectImportMixin = {
  data() {
    return {
      scriptMultiselectLoaded: false,
    };
  },
  async created() {
    if (process.client) {
      const { Multiselect } = await import('@mtntop/validated-input');
      Vue.component('multiselect', Multiselect);
      setTimeout(() => (this.scriptMultiselectLoaded = true));
    }
  },
};

export { validateInputImportMixin, autocompleteImportMixin, multiselectImportMixin };


  import topTableImportMixin from '@/plugins/topTableImportMixin';
  export default {
    name: 'MPowerTableClient',
    mixins: [topTableImportMixin],
    mounted() {
      try {
        if (this.$attrs.customClass) {
          this.$refs.table.$el.querySelector('table').parentNode.className += ` ${this.$attrs.customClass}`;
        }
      } catch (e) {}
    },
  };


  import { mapGetters, mapMutations } from 'vuex';
  import cartItemsMixin from '@/plugins/cartItemsMixin';
  import orderAdjustmentsMixin from '@/components/checkout/orderAdjustmentsMixin';

  export default {
    name: 'NavLink',
    props: ['util'],
    mixins: [cartItemsMixin, orderAdjustmentsMixin],
    components: {
      CategoriesMenuItems: () => import('./CategoriesMenuItems'),
    },
    computed: {
      ...mapGetters({
        cartState: 'cart/cartState',
        isAuthenticated: 'auth/isAuthenticated',
      }),

      navbarCarts() {
        return this.storeInformation.carts.filter((i) => i.type);
      },

      storeSingleCartType() {
        const { notDefault, cart } = this.isNotDefaultCart;
        return notDefault ? cart.type : 'Cart';
      },
      storeSingleCartRoute() {
        const { notDefault, cart } = this.isNotDefaultCart;
        return notDefault ? cart.route : 'cart';
      },

      isNotDefaultCart() {
        const { carts = [] } = this.storeInformation;
        return {
          notDefault: carts.length === 1 && carts[0].type !== 'Cart',
          cart: carts[0],
        };
      },
    },
    methods: {
      ...mapMutations({
        toggleBoxSidebar: 'sidebars/TOGGLE_BOX_SIDEBAR',
        toggleCartSidebar: 'sidebars/TOGGLE_CART_SIDEBAR',
      }),

      isCartUtil(util) {
        return util.type === 'link' && util.href.includes('cart');
      },
      isMultipleCart(util) {
        const { carts = [] } = this.storeInformation;
        return this.isCartUtil(util) && carts.length > 1;
      },
      getTypeOfDropDown(currentType) {
        // when user is signed-in it shows only 'signed-in' type of dropdown
        const isSignedIn =
          (this.isAuthenticated && currentType === 'signed-in') || (this.isAuthenticated && currentType === 'no-type');
        //if not Signed - shows 'not-signed-in' dropdown type (OR any else dropdown without specified 'dropdownType')
        const isntSignedIn =
          (!this.isAuthenticated && currentType === 'not-signed-in') ||
          (!this.isAuthenticated && currentType === 'no-type');
        const result = isSignedIn || isntSignedIn ? true : false;
        return result;
      },
      routeCheck(href) {
        if (href.includes('signout')) {
          this.$store.dispatch('auth/auth_logout');
          this.$router.push({ name: 'signout' }); // ?
        }
        return;
      },
    },
  };


export default {
  name: 'CategoriesMenuItems',
  async fetch() {
    let type = this.link.categoryType;
    if (Array.isArray(type)) type = type[0];
    const { _id: categoryType } = type || {};
    this.categories =
      (
        await this.$api.stores.getRootCategories(
          this.storeInformation._id,
          categoryType || (typeof type === 'string' ? type : '-1') || '-1'
        )
      ).data || [];
  },
  data() {
    return {
      categories: [],
    };
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    navClass: {
      type: String,
      required: false,
    },
  },
};


  import { mapGetters, mapMutations } from 'vuex';
  import cartItemsMixin from '@/plugins/cartItemsMixin';

  export default {
    components: {
      UnsavedBoxItem: () => import('./UnsavedBoxItem'),
      SavedBoxItem: () => import('./SavedBoxItem'),
    },
    data() {
      return {
        boxItemsQuantity: this.sideBarBoxItemsQuantity,
        boxItemsMinQuantity: 0,
        boxItemsPrices: [],
        boxItemsSelectedParts: [],
        savedBoxItemsForUpdate: [],
        boxOrdersPricesState: {},
        boxOrdersPricesLoaded: true,
      };
    },
    props: {
      boxItems: {
        type: Array,
        default: () => [],
      },
      sideBarBoxItemsQuantity: {
        type: Number | String,
        required: true,
      },
    },
    mixins: [cartItemsMixin],
    watch: {
      allNotSaved(val) {
        this.$emit('onLoad', !val);
      },
      sideBarBoxItemsQuantity(val) {
        this.boxItemsQuantity = val;
      },
      boxItemsMinQuantity(val) {
        this.boxItemsQuantity = val;
        this.$emit('onMinQuantityUpdate', val);
      },
      boxItemsMinPrices(val) {
        this.boxItemsMinQuantity = Math.max(0, parseInt(val.length ? Math.min(...val.filter((i) => isFinite(i) && !isNaN(i))) : 0));
      },
      'boxItems.length'() {
        this.boxItemsSelectedParts = [];
        this.boxItemsPrices = [];
      },
      totalBoxItemsPrice(val) {
        this.$emit('onTotalPriceUpdate', val);
      },
    },
    computed: {
      ...mapGetters({
        customer: 'auth/loggedInUser',
        storeCurrencySign: 'storeCurrencySign',
      }),
      boxItemsMinPrices() {
        const boxOrders = this.ordersByCartType('Box');
        const partsMinQuantities = this.boxItemsSelectedParts.map((i) =>
          Math.min(...i.pricing.filter((i) => i.configurationType === 'Decorated').map((i) => i.minQuantity))
        );
        if (boxOrders.length) {
          const lastOrder = boxOrders[boxOrders.length - 1];
          const quantity = lastOrder.quantity / lastOrder.qtyPerBox;
          partsMinQuantities.push(!isNaN(quantity) ? quantity : 0);
        }
        return partsMinQuantities;
      },
      totalBoxItemsPrice() {
        return this.boxItemsPrices.filter((i) => i).reduce((a, b) => a + b, 0);
      },
    },
    methods: {
      ...mapMutations({
        removeUnsavedBoxItem: 'cart/removeUnsavedBoxItem',
        patchUnsavedBoxItem: 'cart/patchUnsavedBoxItem',
      }),
      onLoadSavedBox({ orderId, loaded }) {
        Object.assign(this.boxOrdersPricesState, { [orderId]: loaded });
        const loadPricesValues = Object.values(this.boxOrdersPricesState);
        this.boxOrdersPricesLoaded = !loadPricesValues.length || loadPricesValues.every((i) => i);
        this.$emit('onLoad', this.boxOrdersPricesLoaded);
      },
      boxItemKey(box, index) {
        return `${box?.productData?.productId || box.orderId}-${index}-${this.boxItems.length}`;
      },
      onPODataUpdate(data) {
        const index = this.savedBoxItemsForUpdate.findIndex((i) => i.poId === data.poId);
        if (index > -1) {
          this.$set(this.savedBoxItemsForUpdate, index, data);
        } else this.savedBoxItemsForUpdate.push(data);
      },
      async onCreateBoxItem(orderId, index) {
        if (this.savedBoxItemsForUpdate.length) {
          await this.$api.orders.updatePurchaseOrdersTotals({
            orderId,
            purchaseOrders: this.savedBoxItemsForUpdate,
          });
        }
        this.$emit('onCartLoad', orderId);
        this.onRemoveBoxItem(index);
      },
      onRemoveBoxItem(index, data) {
        this.boxItemsPrices.splice(index, 1);
        this.boxItemsSelectedParts.splice(index, 1);
        this.removeUnsavedBoxItem(index);
        if (data?.poId) {
          const index = this.savedBoxItemsForUpdate.findIndex((i) => i.poId === data.poId);
          if (index > -1) {
            this.savedBoxItemsForUpdate.splice(index, 1);
          }
        }
      },
      onPriceUpdate($event, index) {
        this.$set(this.boxItemsPrices, index, $event);
      },
    },
  };

import Vue from 'vue';

const topTableImportMixin = {
  data() {
    return {
      imported: false,
    };
  },
  async created() {
    if (process.client && !this.imported) {
      const TopTable = await import('@mtntop/top-table');
      Vue.use(TopTable.default);
      setTimeout(() => (this.imported = true));
    }
  },
};

export { topTableImportMixin as default };

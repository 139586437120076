
  export default {
    name: 'IsCloseout',
    props: ['item', 'isFull'],
    data() {
      return {
        text: [`Closeout`, this.isFull && `. Supplies limited`].filter((e) => e).join(''),
      };
    },
  };

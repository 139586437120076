
  import { partUnitPrice } from '@/plugins/cartItemsMixin';
  import { mapGetters } from 'vuex';
  export default {
    name: 'CartItemsViewPartPrice',
    props: {
      part: {
        type: Object,
        required: true,
      },
      po: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapGetters({
        storeCurrencySign: 'storeCurrencySign',
      }),
    },
    data() {
      const { price, discount } = partUnitPrice(this.po, this.part, 0, true, this.index);
      return {
        price,
        discount,
      };
    },
  };

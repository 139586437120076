
  import productPrices from '../../plugins/productPrices';
  import { mapGetters } from 'vuex';

  export default {
    name: 'PartsColor',
    components: {
      MImg: () => import('../MPowerImg'),
      InventoryStatus: () => import('./InventoryStatus'),
      IsCloseout: () => import('./IsCloseout'),
    },
    mixins: [productPrices],
    props: {
      part: Object,
      orderData: Object,
      productData: Object,
      showAdditionalInfo: Boolean,
      hideDescription: Boolean,
      hidePartId: Boolean,
      hideSKU: Boolean,
      inventoryAtBottom: Boolean,
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
        storeCurrencySign: 'storeCurrencySign',
      }),
      image() {
        return this.part.image || null;
      },
    },
    methods: {
      optionTitle(part) {
        const partTitle =
          (!(this.storeInformation.hidePartColor || this.productData.supplierId.hidePartColor) &&
            (part.colorName || part.colorNameLabel)) ||
          (!(this.storeInformation.hidePartDescription || this.productData.supplierId.hidePartDescription) &&
            part.partDescription) ||
          '';
        return partTitle;
      },
    },
  };

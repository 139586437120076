
import productPricesMixin from '@/plugins/productPrices';
import { mapGetters } from 'vuex';
import { calcOrderTotal } from '@/plugins/cartItemsMixin';

export default {
  components: {
    PartsColors: () => import('../productView/PartsColors'),
  },
  data() {
    return {
      quantity: this.qtyPerBox,
      chooseColorOnInit: false,
      loading: false,
      selectedPart: null,
      orderData: { quantity: null },
      productData: null,
      cartItemToDelete: null,
      removingBox: false,
      priceLoading: false,
    };
  },
  watch: {
    boxItemQuantity(val) {
      this.updatePOData(val);
      if (this.selectedPart && this.selectedPart.ApparelSize) {
        this.allPartsSizes = this.order.LineItemArray[0].PartArray.map((el) => ({
          ...el.ApparelSize,
          quantity: val,
          _id: this.selectedPart._id,
        }));
      }
    },
    boxTotalPrice(val) {
      this.$emit('onPriceUpdate', val || 0);
    },
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    itemsQuantity: {
      type: Number | String,
      required: true,
    },
  },
  mixins: [productPricesMixin],
  async mounted() {
    if (this.order.orderId) {
      this.priceLoading = true;
      this.$emit('onLoad', { orderId: this.order.orderId, loaded: false });
      const partId = this.order.parts[0].partId;
      const slug = this.order.LineItemArray[0].localProduct.slug;
      this.productData = await this.$api.products.getProductBySlug(slug, { partId }); //todo refactor to load only part without product data;
      this.$emit('onLoad', { orderId: this.order.orderId, loaded: true });
      this.priceLoading = false;
      this.selectedPart = this.productData.parts[0];
      this.updatePOData(this.boxItemQuantity);
      this.$emit('onPartUpdate', this.selectedPart);
    }
  },
  computed: {
    ...mapGetters({
      storeCurrencySign: 'storeCurrencySign',
    }),
    boxItemQuantity() {
      return this.order.qtyPerBox * this.itemsQuantity;
    },
    boxTotalPrice() {
      if (this.selectedPart) {
        const total = this.calculateTotalPrice(this.boxItemQuantity);
        let charge = parseFloat(this.order.chargeTotal);
        return this.boxItemQuantity > 0 ? total.total + charge : 0;
      }
      return 0;
    },
  },
  methods: {
    calcOrderTotal,
    updatePOData(quantity) {
      if (this.selectedPart) {
        const total = this.calculateTotalPrice(quantity);
        this.$emit('onPODataUpdate', {
          poId: this.order.poId,
          ...total,
          quantity,
        });
      }
    },
    calculateTotalPrice(quantity) {
      this.selectedPart.quantity = quantity;
      this.orderData.quantity = quantity;
      this.setPartsSizesQuantity(quantity);
      return this.calcBoxTotal(this.selectedPart, [this.selectedPart], this.boxItemQuantity);
    },
    async deleteCartItem() {
      try {
        this.removingBox = true;
        this.$emit('onPriceUpdate', 0);
        this.$refs.deleteConfirm.hide();
        await this.$store.dispatch('cart/deleteOrder', { order: this.cartItemToDelete, cartType: 'Box' });
        this.cartItemToDelete = null;
      } catch (e) {
        this.$emit('onPriceUpdate', this.boxTotalPrice);
        console.log(e);
      } finally {
        this.removingBox = false;
      }
    },
    deleteConfirm() {
      this.cartItemToDelete = this.order;
      this.$refs.deleteConfirm.show();
    },
  },
};

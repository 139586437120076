
  import { validateInputImportMixin } from '@/plugins/validate-input';

  export default {
    inject: ['$validator'],
    name: 'MPowerValidateInput',
    mixins: [validateInputImportMixin],
    components: {
      MPowerValidateInputWrapper: () => import('./MPowerValidateInputWrapper'),
    },
  };

const selectedDefDecorationMixin = {
  methods: {
    _defaultSelectedDecoration() {
      const product = this.productData;
      if (!product) {
        console.log('NO Product');
        return false;
      }
      let decoration = this.$route.query.decoration;
      if (decoration) {
        decoration = `${decoration}`.toLowerCase();
        let location = this._defaultSelectedLocation();
        if (location) {
          // todo bad condition. refactor
          return location.decorations.find(
            (i) =>
              `${i.decorationId || i.decoration_id || ''}`.toLowerCase() === decoration ||
              (i._id && i._id.toString().toLowerCase()) === decoration ||
              (i.name || '').toLowerCase() === decoration
          );
        }
      }
    },
    _defaultSelectedLocation() {
      const product = this.productData;
      if (!product) {
        console.log('NO Product');
        return false;
      }
      let decoration = this.$route.query.decoration;
      let locationId = this.$route.query.location;
      if (locationId) {
        locationId = locationId.toLowerCase();
        const location = product.locations.find(
          (e) =>
            (e.locationId || e.location_id || e._id) === locationId || (e.name && e.name.toLowerCase() === locationId)
        );
        return location;
      } else {
        const locations = product.locations;
        return (
          (product.selectedDecoration &&
            product.selectedDecoration.location &&
            locations.find((e) => e._id === product.selectedDecoration.location._id)) ||
          locations.find((e) => e.default) ||
          locations.find((e) =>
            e.decorations.find(
              (i) =>
                i.default &&
                (`${i.decorationId || i.decoration_id || ''}`.toLowerCase() === decoration ||
                  (i.name || '').toLowerCase() === decoration)
            )
          ) ||
          locations.find((e) =>
            e.decorations.find(
              (i) =>
                `${i.decorationId || i.decoration_id || ''}`.toLowerCase() === decoration ||
                (i.name || '').toLowerCase() === decoration
            )
          )
        );
      }
    },
  },
  computed: {
    product() {
      return this.productData;
    },
    defaultSelectedLocation() {
      return this._defaultSelectedLocation();
    },
    defaultSelectedDecoration() {
      return this._defaultSelectedDecoration();
    },
  },
};
const createOrderRequestDefDecorationMixin = {
  methods: {
    confirmConfigureProduct(e) {
      const { carts = [] } = this.storeInformation;
      const cart = carts.find((i) => i.type === 'Cart');
      let path = '/cartitemsadded';
      let addQuery = true;
      if (cart) {
        if (cart.skipAddToCartPage) {
          addQuery = false;
          path = `/${cart.route}`;
        }
      }
      this.$router.push({ path, query: addQuery && { ...e } });
    },
  },
  mixins: [selectedDefDecorationMixin],
  computed: {
    backProductUrl() {
      const { partId } = this.$route.query;
      return `/products/${this.productData.slug}${partId ? `?partId=${partId}` : ''}`;
    },
    defaultDecoration() {
      if ((this.productData && this.productData.isQuoteOnly) || !this.product) return null;

      let decoration =
        (this.product &&
          (this.product.selectedDecoration ||
            this.product.decorations[0] ||
            (this.product.locations.length && this.product.locations[0].decorations[0]))) ||
        this.defaultSelectedDecoration ||
        null;
      if (this.product && this.product.selectedDecoration) {
      } else if (decoration && decoration.charges && decoration.charges[0]) {
        let defCharge =
          decoration.charges.find((e) => e.type === 'setup' && e.pricing.find((e) => parseFloat(e.price))) ||
          decoration.charges[0];
        const setUpCharge = {
          ...defCharge,
          ...(defCharge.pricing.find((e) => parseFloat(e.price)) || defCharge.pricing[0]),
        };
        decoration.setUpCharge = decoration.setupCharge = setUpCharge;
        decoration.setupChargePrice = setUpCharge.price;
      }
      return decoration;
    },
  },
};

export { createOrderRequestDefDecorationMixin as default, selectedDefDecorationMixin };

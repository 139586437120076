import { SIZES } from '../utils/productSizes';

export const partsColors = (partGroup, parts = [], canSkipPricing = false, query) => {
  if (!partGroup) partGroup = '1';
  const exist = [];
  parts = parts.filter(
    (el) =>
      (el.partGroup === partGroup || (typeof el.partGroup === 'undefined' && partGroup === '1')) &&
      el.status !== 'discontinued' &&
      (el.partId === 'NONE' || canSkipPricing || (el.pricing && el.pricing.length))
  );

  const withNextGroup = parts.filter((el) => el.nextPartGroup > el.partGroup);
  const withoutNextGroup = parts.filter((el) => el.nextPartGroup <= el.partGroup || !el.nextPartGroup);
  parts = [
    ...withNextGroup.filter((el) => el.partId || el.part_id),
    ...withoutNextGroup.filter((el) => el.partId || el.part_id),
    ...withNextGroup.filter((el) => !(el.partId || el.part_id)),
    ...withoutNextGroup.filter((el) => !(el.partId || el.part_id)),
  ].filter((e) => e);
  const res = {};
  for (let i = 0; i < parts.length; i++) {
    const el = parts[i];
    el.quantity = el.quantity || el.minQuantity;
    let colorName =
      (partGroup === '1'
        ? el.colorName && el.colorName.toLowerCase()
        : (el.ApparelSize && el.ApparelSize.label_size) || el.partId || (el.colorName && el.colorName.toLowerCase())) ||
      el.partId;

    if (el.ApparelSize && el.ApparelSize.label_size && !el.ApparelSize.code) {
      el.ApparelSize.code = SIZES.indexOf(el.ApparelSize.label_size.toUpperCase());
    }
    if (!el.ApparelSize || !el.ApparelSize.label_size) {
      colorName += el.partDescription;
    }
    if (partGroup > '1' && el.partGroup > '1') {
      colorName += el.partId;
    }
    colorName = colorName.substr(0, 50);

    if (!el.minPrice) {
      if (el.pricing && el.pricing[0]) {
        const { price, quantity, unitPrice, minQuantity } = el.pricing[0];
        el.price = price || unitPrice;
        el.minPrice = el.price * (quantity || minQuantity);
      } else {
        el.minPrice = Infinity;
      }
    }
    if (
      !res[colorName] ||
      (colorName &&
        res[colorName] &&
        (res[colorName].minPrice > el.minPrice ||
          (el.ApparelSize &&
            res[colorName].ApparelSize &&
            res[colorName].minPrice.toFixed(5) === el.minPrice.toFixed(5) &&
            res[colorName].ApparelSize.code > el.ApparelSize.code))) ||
      (query && query.partId && query.partId === el.partId)
    ) {
      res[colorName] = el;
    }
  }
  return Object.values(res).sort((a, b) => ((a.partGroup || 100) >= (b.partGroup || 100) ? 1 : -1)) || [];
};

export const drawImageFromColor = (color, colorName) => {
  if (color && process.client) {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 300;
    const div = document.createElement('div');
    div.style.backgroundColor = color;
    if (!div.style.backgroundColor) {
      const c = color.split(' ').filter((e) => {
        if (!e) return false;
        const div = document.createElement('div');
        div.style.backgroundColor = e;
        return div.style.backgroundColor;
      });
      div.style.backgroundColor = `linear-gradient(90deg, ${c.join(', ')})`;
    }
    if (!div.style.backgroundColor && colorName) {
      const colors =
        colorName
          .toLowerCase()
          .split(' ')
          .filter((e) => {
            if (!e) return false;
            const div = document.createElement('div');
            div.style.backgroundColor = e;
            return div.style.backgroundColor;
          }) || [];
      if (colors.length > 1) {
        div.style.backgroundColor = `linear-gradient(90deg, ${colors.join(', ')})`;
      } else if (colors.length) {
        div.style.backgroundColor = colors[0];
      }
    }
    color = div.style.backgroundColor;
    if (!color) return;
    const cntx = canvas.getContext('2d');
    if (color.match('gradient')) {
      let colors = color.substr(color.indexOf('deg,') + 5) || '';
      colors = colors.substr(0, colors.length - 1);
      const grd = cntx.createLinearGradient(0, 0, canvas.width, 0);
      colors = colors.split(',');
      colors.forEach((color, index) => grd.addColorStop(index / (colors.length - 1), color));
      cntx.fillStyle = grd;
    } else {
      cntx.fillStyle = color;
    }
    cntx.fillRect(0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  }
};


  import Cookies from 'js-cookie';
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        redeemedCode: null,
        redemptionCodeInterval: null,
      };
    },
    props: {
      ordersCountByType: {
        type: Function,
        required: true,
      },
    },
    watch: {
      redeemedCode(newVal) {
        if (newVal) {
          clearInterval(this.redemptionCodeInterval);
        }
      },
    },
    mounted() {
      this.checkRedemptionCode();
      this.redemptionCodeInterval = setInterval(this.checkRedemptionCode, 1000);
    },
    beforeDestroy() {
      clearInterval(this.redemptionCodeInterval);
    },
    computed: {
      ...mapGetters({
        orders: 'cart/orders',
        storeInformation: 'storeInformation',
      }),
      availableItems() {
        return parseFloat(this.redeemedCode.ordersAmount) - this.orders.reduce((acc, order) => acc + order.quantity, 0);
      },
    },
    methods: {
      checkRedemptionCode() {
        const code = Cookies.get('REDEMPTION_CODE');
        this.redeemedCode = code ? JSON.parse(code) : null;
        if (this.redeemedCode?.storeId?.toString() !== this.storeInformation._id.toString()) {
          this.redeemedCode = null;
        }
      },
    },
  };

import Cookies from 'js-cookie';

const KEY = 'search-history-1';
const MAX_HISTORY_LENGTH = 15;
export class SearchHistory {
  constructor() {
    try {
      this.list = JSON.parse(localStorage.getItem(KEY) || null) || Cookies.getJSON(KEY) || [];
      if (this.list.length > MAX_HISTORY_LENGTH) {
        this.list = this.list.splice(0, MAX_HISTORY_LENGTH);
        this.save();
      }
    } catch (e) {
      this.list = [];
      this.save();
    }
  }

  add(searchTerm) {
    this.list.unshift(searchTerm);
    this.list = _.uniq(this.list).splice(0, MAX_HISTORY_LENGTH);
    this.save();
  }

  remove(index) {
    this.list.splice(index, 1);
    this.save();
  }
  removeAll() {
    this.list.splice(0, this.list.length);
    this.save();
  }

  save() {
    localStorage.setItem(KEY, JSON.stringify(this.list));
  }
}
